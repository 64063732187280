<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <b-card>
          <b-card-text>
            <div>
              <b-form @reset="resetForm">
                <b-form-group
                  label="Tipo de documento:"
                  v-if="isEdit === false"
                >
                  <b-form-select
                    v-model="form.document_type"
                    :options="document_type_options"
                  ></b-form-select>
                </b-form-group>

                <b-form-group label="Número de documento">
                  <b-form-input
                    v-model="form.doc_number"
                    type="text"
                    required
                    @keyup.enter="getPersonDNIData()"
                  ></b-form-input>
                </b-form-group>

                <b-form-group :label="firstName">
                  <b-form-input
                    v-model="form.first_name"
                    type="text"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label="Primer apellido:"
                  v-if="form.document_type !== '6'"
                >
                  <b-form-input
                    v-model="form.last_name"
                    type="text"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label="Segundo apellido:"
                  v-if="form.document_type !== '6'"
                >
                  <b-form-input
                    v-model="form.last_name_2"
                    type="text"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Dirección:">
                  <b-form-input
                    v-model="form.address"
                    type="email"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Correo:">
                  <b-form-input
                    v-model="form.email"
                    type="email"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Teléfono:">
                  <b-form-input
                    v-model="form.telephone"
                    type="text"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="">
                  <b-form-checkbox v-model="form.is_provider" size="lg">
                    Es un proveedor
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group label="">
                  <b-form-checkbox v-model="form.is_agreement" size="lg">
                    ¿Tiene convenio?
                  </b-form-checkbox>
                </b-form-group>
                <b-button variant="primary" class="mr-2" @click="submit"
                  >Guardar</b-button
                >
                <b-button type="reset" variant="danger" v-if="isEdit === false"
                  >Limpiar</b-button
                >
              </b-form>
            </div>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "PersonCreate",
  data() {
    return {
      form: {
        is_provider: false,
        is_agreement: false,
        first_name: null,
        last_name: null,
        last_name_2: null,
        doc_number: null,
        address: null,
        telephone: null,
      },
      form_reset: {},
      document_type_selected: null,
      document_type_options: [
        { value: "1", text: "DNI" },
        { value: "4", text: "Carnet de extranjería" },
        { value: "6", text: "RUC" },
        { value: "7", text: "Pasaporte" },
        { value: "0", text: "Otros tipos de documentos" },
      ],
      isEdit: false,
      action: "Crear",
    };
  },
  components: {},
  mounted() {
    this.form_reset = { ...this.form };
    this.getPersonIsEdit();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Persona", route: "/person" },
      { title: `${this.action} persona` },
    ]);
  },
  methods: {
    getPersonDNIData() {
      if (this.form.document_type === "1") {
        const bufferDoc = this.form.doc_number;
        ApiService.get(
          "core/person/dni-ruc",
          `?doc_number=${this.form.doc_number}`
        ).then((res) => {
          this.resetForm();
          this.form.doc_number = bufferDoc;
          this.form.first_name = res.data.first_name;
          this.form.document_type = "1";
          this.form.last_name = res.data.last_name;
          this.form.last_name_2 = res.data.last_name_2;
        });
      }
    },
    getPersonIsEdit() {
      console.log(this.$route);
      if ("id" in this.$route.params) {
        this.isEdit = true;
        this.action = "Editar";
        ApiService.get(`core/person/${this.$route.params.id}`).then((res) => {
          this.form = res.data;
        });
      }
    },
    resetForm() {
      this.form = { ...this.form_reset };
    },
    submit() {
      let method = this.isEdit === true ? ApiService.put : ApiService.post;
      let url =
        this.isEdit === true ? `core/person/${this.form.id}/` : "core/person/";

      method(url, this.form)
        .then(() => {
          this.$successToast();
          if (this.isEdit === false) {
            this.resetForm();
          }
        })
        .catch((err) => {
          console.log(err);
          this.$errorToast(err);
        });
    },
  },
  computed: {
    firstName: function () {
      return this.form.document_type === "6" ? "Razón social" : "Nombre";
    },
  },
};
</script>
